<template>
  <div class="promotionPage">
    <div class="promotionPage_img">
      <div>
        <img src="~@/assets/images/banner1.jpg" />
      </div>
      <div style="margin: 50px 44.5px 0 44.5px">
        <img src="~@/assets/images/display1.jpg" />
      </div>
      <div class="promotionPage_img_third">
        <img
          src="~@/assets/images/display2.jpg"
          style="padding: 44.5px 0 44.5px 0; cursor: pointer"
          @click="clickImg1"
        />
      </div>
      <div class="promotionPage_img_third">
        <img
          src="~@/assets/images/display3.jpg"
          style="padding: 44.5px 0 44.5px 0; cursor: pointer"
          @click="clickImg2"
        />
      </div>
      <div class="promotionPage_img_third">
        <img
          src="~@/assets/images/display4.jpg"
          style="padding: 44.5px 0 44.5px 0; cursor: pointer"
          @click="clickImg3"
        />
      </div>
      <div class="promotionPage_img_third">
        <img
          src="~@/assets/images/display5.jpg"
          style="padding: 44.5px 0 44.5px 0; cursor: pointer"
          @click="clickImg4"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    //跳转到详情页
    clickImg1() {
      let { href } = this.$router.resolve({
        path: "/web/goodsDetail",
        query: {
          id: 6939837675409,
        },
      });
      window.open(href);
    },
    clickImg2() {
      let { href } = this.$router.resolve({
        path: "/web/goodsDetail",
        query: {
          id: 6941241633951,
        },
      });
      window.open(href);
    },
    clickImg3() {
      let { href } = this.$router.resolve({
        path: "/web/goodsDetail",
        query: {
          id: 6970517883912,
        },
      });
      window.open(href);
    },
    clickImg4() {
      let { href } = this.$router.resolve({
        path: "/web/goodsDetail",
        query: {
          id: 6941241629084,
        },
      });
      window.open(href);
    },
  },
};
</script>
<style lang="scss" scoped>
.promotionPage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &_img {
    width: 1198px;
    &_third {
      margin-top: 50px;
      width: 1198px;
      background: #f5f8fa;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
